import React, { useState } from "react"
import Layout from "../components/layout"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"
import { Toolset, Title } from "../components/parts"

const Page = () => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = (event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <Layout title="Photography">
      <Title heading="Photography" sub="New eyes, learning to see" />
      <div className="section-bottom">
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox} backdropClosesModal={true}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
                trackProps={{
                  swipe: "touch",
                  infinite: false,
                }}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
      <Toolset tools={["lightroom", "photoshop"]} />
    </Layout>
  )
}

export default Page

const photos = [
  {
    src: "/images/photography/1.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "/images/photography/2.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "/images/photography/3.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "/images/photography/4.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "/images/photography/5.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "/images/photography/6.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "/images/photography/7.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "/images/photography/8.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "/images/photography/9.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "/images/photography/10.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/photography/11.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "/images/photography/12.jpg",
    width: 2,
    height: 3,
  },
  // {
  //   src: "/images/photography/13.jpg",
  //   width: 3,
  //   height: 2,
  // },
  {
    src: "/images/photography/14.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "/images/photography/15.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/photography/16.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/photography/17.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "/images/photography/18.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "/images/photography/19.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "/images/photography/20.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "/images/photography/21.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/photography/22.jpg",
    width: 2,
    height: 3,
  },
  // {
  //   src: "/images/photography/23.jpg",
  //   width: 2,
  //   height: 3,
  // },
  {
    src: "/images/photography/24.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/photography/25.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "/images/photography/26.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "/images/photography/27.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "/images/photography/28.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "/images/photography/29.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/photography/30.jpg",
    width: 2,
    height: 3,
  },
]
